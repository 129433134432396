/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-default-layout-sidebar-tabs {
  display: flex;
  height: 100%;
  width: 100%;
}
.rpv-default-layout-sidebar-headers {
  align-items: center;
  background-color: #eeeeee;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px;
}
.rpv-default-layout-sidebar-header {
  padding: 2px;
}
.rpv-default-layout-sidebar-content {
  display: none;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 8px 0px;
}
.rpv-default-layout-sidebar-content-opened {
  display: flex;
}
/**
 * A React component to view a PDF document
 *
 * @see https://react-pdf-viewer.dev
 * @license https://react-pdf-viewer.dev/license
 * @copyright 2019-2020 Nguyen Huu Phuoc <me@phuoc.ng>
 */
.rpv-default-layout-container {
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}
.rpv-default-layout-toolbar {
  background-color: #eeeeee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 4px;
}
.rpv-default-layout-sidebar {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.rpv-default-layout-sidebar-opened {
  width: 30%;
}
.rpv-default-layout-main {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}
.rpv-default-layout-body {
  flex: 1;
  overflow: auto;
}
